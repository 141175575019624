$primary: #FF5000;

$active-color: $primary;

$border-color: #E3EBF6;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "~react-dates/lib/css/_datepicker.css";

$sidebar-width: 250px;
$sidebar-width-collapsed: 93px;

$sidebar-transition-speed: 0.3s;
$sidebar-transition-curve: cubic-bezier(.93,0,.22,1);
$sidebar-overlay-curve: ease;

@import "~easymde/dist/easymde.min.css";

@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

@import "~react-calendar-heatmap/dist/styles.css";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "~@zandor300/backoffice-framework/lib/scss/backoffice-framework";

@import "badge";
@import "comments";
@import "dashboard";
@import "heatmap";
@import "logo";
@import "reminders";

@page {
  size: auto;
}

.text-vertical {
  writing-mode: vertical-rl !important;
  text-orientation: mixed !important;
}

.card-comment {
  margin-bottom: 15px;
}

.detail-header-cell {
  text-align: right;
  width: 250px;
}

.detail-header-cell-sm {
  text-align: right;
  width: 250px;
}

.card-qrcode {
  @media print {
    display: block !important;
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.card-arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 20px 40px 0;
  border-color:
          transparent #fff transparent
          transparent;
  float: left;
}
.card-arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 40px 20px;
  border-color:
          transparent transparent
          transparent #fff;
  float: right;
}
.left-border-0 {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.right-border-0 {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mb-0-p-last {
  p:last-child {
    margin-bottom: 0;
  }
}
